<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-4>
          <v-layout row justify-space-between align-center>
            <v-flex xs12 sm6 md6 lg3>
              <span class="heading">District Officers List</span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 pt-2>
                 
              <v-select
                outlined
                hide-details
                dense clearable
                :items="districts"
                v-model="district"
                placeholder="Filter By District"
                style="font-family: sofiaProRegular;"
              >
              </v-select>
            </v-flex>
            <v-flex xs12 sm12 md12 lg3 pt-2 pt-lg-0 pt-md-0 pt-sm-0 mt-2 class="text-right">
                <v-btn
                  color="#fdac53"
               
                   @click="adddialog = true"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: black;
                    "
                  >
                    Add District Officer
                    <v-icon color="black">mdi-plus</v-icon>
                  </span>
                </v-btn>
              </v-flex>
             
          </v-layout>

          <!-- <v-flex xs12>
              <span class="heading">Rescuer List</span>
            </v-flex>
            <v-flex xs12>
              <v-btn
                color="#fdac53"
                class="downlink"
                target="_blank"
                @click="getExcel()"
              >
                <span
                  style="
                    font-size: 12px;
                    font-family: poppinssemibold;
                    color: black;
                  "
                >
                  Excel<v-icon color="black">mdi-microsoft-excel</v-icon></span
                >
              </v-btn>
            </v-flex> -->
          <v-flex xs12 v-if="userlist && userlist.length > 0">
            
            <v-layout wrap justify-start pt-2>
              <v-flex xs12>
                <v-card>
                  <div style="overflow-y: auto;">
                    <v-simple-table>
                      <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left tablehead">Name</th>
                         
                          <th class="text-left tablehead">Phone</th>
                          <!-- <th class="text-left tablehead">Email</th> -->
                          <th class="text-left tablehead">District</th>
                         
                          
                          <th class="text-left tablehead">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in userlist" :key="index">
                          <td style="white-space: nowrap;"  class="tableitems">{{item.name}}</td>
                          <td style="white-space: nowrap;"  class="tableitems">{{item.phone}}</td>
                          <!-- <td class="tableitems">{{item.email}}</td> -->
                          <td style="white-space: nowrap;"  class="tableitems">{{item.district}}</td>

                          
                         
                          
                          <td style="white-space: nowrap;" >
                            <v-icon
                            small
                            color="error"
                            class="ml-2"
                            @click.stop="rejectDialog(item._id)"
                          >mdi-delete</v-icon>
                           
                          </td>
                          <!-- <td>
                            <v-btn
                              color="error"
                              depressed
                              small
                              @click="openrejectDialog(item)"
                              ><span>Reject</span></v-btn
                            >
                          </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  </div>
               
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 v-else>
            <v-layout wrap justify-center align-center>
              <v-flex xs12 class="text-center">
                <span class="nodata">No Data Found !</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="userlist && userlist.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              circle
              color="#FF6907"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
     
     
    </v-layout>
    <v-dialog v-model="adddialog" max-width="800px">
        <v-card rounded="lg">
            <v-card-title>
                <span class="heading">ADD DISTRICT OFFICER</span>
                <v-spacer></v-spacer>
                <v-btn color="red" icon @click="adddialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-form >
                    <v-layout wrap justify-start pt-2>
                        <v-flex xs12 sm12 md6 lg6 pr-2 class="subheading">
                            <span style="color:black">User Name</span>
                            <v-text-field class="pt-2 text-des" outlined v-model="username" dense hide-details>
                            </v-text-field>
                          </v-flex>
                        <v-flex xs12 sm12 md6 lg6 class="subheading">
                          <span style="color:black">Name</span>
                          <v-text-field class="pt-2 text-des" outlined v-model="name" dense hide-details>
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 pt-2 pr-2 class="subheading">
                            <span style="color:black">Phone</span>
                            <v-text-field class="pt-2 text-des" outlined v-model="phone" dense hide-details>
                            </v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md6 lg6 pt-2 class="subheading">
                            <span style="color:black">Email</span>
                            <v-text-field class="pt-2 text-des" outlined v-model="email" dense hide-details>
                            </v-text-field>
                          </v-flex>

                          <v-flex xs12 sm12 md12 lg12 pt-2 class="subheading">
                            <span style="color:black">Select District</span>
                            <v-select
                              outlined
                              hide-details
                              dense
                              :items="districts" class="pt-2 text-des"
                              v-model="district1"
                              placeholder="Select District"
                              style="font-family: sofiaProRegular;"
                            ></v-select>
                          </v-flex>

                          
                          <v-flex xs12 sm12 md12 lg12 pt-2  class="subheading">
                            <span style="color:black">Password</span>
                            <v-text-field class="pt-2 text-des" outlined v-model="password" dense hide-details>
                            </v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12 lg12 pt-2  class="subheading">
                            <span style="color:black"> Confirm Password</span>
                            <v-text-field class="pt-2 text-des" outlined v-model="cpassword" dense hide-details>
                            </v-text-field>
                          </v-flex>

                      
                      </v-layout>
                </v-form>
            </v-card-text>

         

            <v-card-actions class="py-4 justify-end headline  lighten-2">
                <v-btn tile
                outlined
                color="#FF1313"
                light
                :ripple="false"
                depressed
             
                class="itemValue" text @click="adddialog = false"><span
                        style="color: black;">Cancel</span> </v-btn>
                <v-btn  tile
                :color="appColor"
                light
                :ripple="false"
                depressed
            
                class="itemValue" @click="itemadd()"><span style="color:white;">Save Changes</span> </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Delete District Officer</v-card-title>
        <v-card-text>
          Are you sure you want to delete this rescuer?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteUser()"
            class="itemValue"
          >
            <span style="color: #fff">Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
        <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      count: 20,
      keyword: "",
      name:"",
      username:"",
      password:"",
      cpassword:"",
      phone:"",
      email:"",
      adddialog:false,
      currentPage: 1,
      Pagelength: 0,
      userlist: [],
      district:"",
      district1:"",
      districts: [
        "Angul",
        "Boudh",
        "Balangir",
        "Bargarh",
        "Balasore",
        "Bhadrak",
        "Cuttack",
        "Deogarh",
        "Dhenkanal",
        "Ganjam",
        "Gajapati",
        "Jharsuguda",
        "Jajpur",
        "Jagatsinghapur",
        "Khordha",
        "Keonjhar",
        "Kalahandi",
        "Kandhamal",
        "Koraput",
        "Kendrapara",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nuapada",
        "Nayagarh",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur",
        "Sundargarh",
      ],
      deleteDialog: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    district() {
      this.getData();
    },
  },
  methods: {
    // openviewdetails(item) {
    //   this.$router.push({
    //     path: "/rescuerslistview",
    //     query: {
    //       id: item._id,
    //     },
    //   });
    // },
    // getExcel() {
    //   this.appLoading = true;
    //   axios({
    //     method: "GET",
    //     url: "/rescuer/getlist/download/excel",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     responseType: "blob",
    //     params: {
    //       keyword: this.keyword,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       const url = URL.createObjectURL(
    //         new Blob([response.data], {
    //           type: "application/vnd.ms-excel",
    //         })
    //       );
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", "file.xlsx");
    //       document.body.appendChild(link);
    //       link.click();
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    rejectDialog(item) {
      this.deleteDialog = true;
      this.deleteid = item;
    },
    // openeditdetails(item) {
    //   this.$router.push({
    //     path: "/newrescuerslistedit",
    //     query: {
    //       id: item.user._id,
    //     },
    //   });
    // },
    deleteUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/districtofficer/delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.deleteid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Deleted Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.deleteDialog = false;
              this.getData();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.deleteDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    itemadd() {
        if (!this.username) {
       this.msg = "Please Provide UserName.";
       this.showSnackBar = true;
       return;
     }
     if (!this.name) {
       this.msg = "Please Provide Name.";
       this.showSnackBar = true;
       return;
     }
     if (!this.phone) {
       this.msg = "Please Provide Phone.";
       this.showSnackBar = true;
       return;
     }
     if (!this.email) {
       this.msg = "Please Provide Email.";
       this.showSnackBar = true;
       return;
     }
     if (!this.district1) {
       this.msg = "Please select district.";
       this.showSnackBar = true;
       return;
     }
    
    
     if (!this.password) {
    this.msg = "Please Provide Password.";
    this.showSnackBar = true;
    return;
  }
  if (!this.cpassword) {
    this.msg = "Please Confirm Password.";
    this.showSnackBar = true;
    return;
  }
  if (this.password !== this.cpassword) {
    this.msg = "Password and Confirm Password must be the same.";
    this.showSnackBar = true;
    return;
  }
    
     
     this.appLoading = true;
     axios({
       method: "POST",
       url: "/user/districtofficer/register",
       headers: {
         "x-auth-token": localStorage.getItem("token"),
       },
       data: {
         username: this.username,
         name: this.name,
         password: this.password,
         cpassword: this.cpassword,
         phone: this.phone,
         email: this.email,
         district: this.district1,

        
       },
     })
     .then((response) => {
                   this.appLoading = false;
                   if (response.data.status) {
                       this.msg = response.data.msg;
                       this.showSnackBar = true;
                       this.adddialog = false;
                    
                       this.name = null;
                       this.desc = null;
                    
                       this.getData();
                   } else {
                       this.msg = response.data.msg;
                       this.showSnackBar = true;
                   }
               })
       .catch((err) => {
         this.appLoading = false;
         (this.ServerError = true), console.log(err);
       });
   },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/district/emergencyofficer/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          district: this.district,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.userlist = response.data.data;
              this.Pagelength = response.data.pages;
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
   
  },
};
</script>
        <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>